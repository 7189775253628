import React, { useEffect } from 'react'
import { Link, navigate, useIntl } from 'gatsby-plugin-intl'
import Layout from '../../components/Layout/index'
import Header from '../../modules/Header'
import Footer from '../../modules/Footer'
import SEO from '../../components/seo'
import PressRelease from '../../components/PressRelease'

import messages from '../../messages/pageTitles.lang'
import metaDescription from '../../messages/pageMetaDescriptions.lang'
import Image from '../../images/news/press-release-mefa-secondary.png'

import { newsList } from '../../components/NewsPage/data'

const IndexPage = () => {
  const { formatMessage, locale } = useIntl()

  useEffect(() => {
    if (global.window && locale === 'en') {
      navigate('/newsroom/')
    }
  }, [])

  return (
    <Layout footer={<Footer />} header={<Header withTeaser />}>
      <SEO
        title={formatMessage(messages.TitleNewsMefa)}
        imageAlt={formatMessage(messages.TitleNewsMefa)}
        description={formatMessage(metaDescription.NewsMefaDescription)}
        image={Image}
        nofollow={locale === 'en'}
        lang={locale}
      />
      <PressRelease
        article={newsList.mefa}
        articleLinks={{
          dev: <Link to="/services/development/">https://www.freshcells.de/de/services/development</Link>,
          emea: (
            <a href="https://emea.mitsubishielectric.com/fa" target="_blank" rel="noopener noreferrer">
              https://emea.mitsubishielectric.com/fa
            </a>
          )
        }}
      />
    </Layout>
  )
}

export default IndexPage
